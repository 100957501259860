import { Device } from "../components/returns/DeviceForm"

// Used on returns form
// https://github.com/Rachio/mailchimp-application/blob/master/messages.js
export const triggerEmailWorkflow = (
  {
    address,
    branch,
    companyName,
    devices,
    email,
    phone,
    requestedBy,
  }: {
    address: string
    branch: string
    companyName: string
    devices: Device[]
    email: string
    phone: string
    requestedBy: string
  },
  submissionId: string
) => {
  const url =  String( process.env.GATSBY_RACHIO_MAILCHIMP_URL )

  const headers = {
    "X-Api-Key": process.env.GATSBY_RACHIO_MAILCHIMP_KEY,
    "Content-Type": "application/json",
  }

  const formattedDevices = devices.map((device: Device, i: number) => {
    return {
      name: `ITEM_${i}`,
      content: `SKU: ${device.sku} SERIAL: ${device.serial} METHOD: ${device.credit_or_replace} REASON: ${device.reason}`,
    }
  })

  const globalMergeVars = [
    { name: "SUBMISSION_ID", content: submissionId },
    { name: "REQUESTED_BY", content: requestedBy },
    { name: "COMPANY_NAME", content: companyName },
    { name: "BRANCH", content: branch },
    { name: "ADDRESS", content: address },
    { name: "PHONE", content: phone },
    ...formattedDevices,
  ]

  const body = JSON.stringify({
    to: [
      {
        email,
        type: "to",
      },
      {
        email: "will.upton@rachio.com",
        type: "bcc",
      },
      {
        email: "operations@rachio.com",
        type: "bcc",
      },
    ],
    fromEmail: "prosupport@rachio.com",
    fromName: "Rachio Pro Services",
    templateName: "pro-rga-submission-confirmation",
    subject: "RGA Submission Confirmation",
    globalMergeVars,
  })

  return fetch(url, {
    method: "POST",
    headers,
    body,
  })
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => {
      console.error("error", error)
      throw new Error(error)
    })
}
