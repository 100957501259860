import create from "zustand"
import produce from "immer"

const immer = config => (set, get) => config(fn => set(produce(fn)), get)

const defaultDevice = {
  sku: "",
  serial: "",
  credit_or_replace: "credit",
  reason: "",
}

const defaultReturn = {
  requestedBy: "",
  companyName: "",
  branch: "",
  email: "",
  phone: "",
  address: "",
  devices: [defaultDevice],
}

const store = (set: (updater: any) => void) => ({
  returnData: defaultReturn,

  initialize: def =>
    set(state => {
      state.returnData = def
    }),

  updateReturn: ({ name, value }: { name: string; value: string }) => {
    set(state => {
      state.returnData[name] = value
    })
  },

  updateDevice: ({ index, name, value }) =>
    set(state => {
      state.returnData.devices[index][name] = value
    }),

  updateDeviceRadio: ({ index, value }) =>
    set(state => {
      state.returnData.devices[index].credit_or_replace = value
    }),

  removeDevice: (index: number) =>
    set(state => {
      if (state.returnData.devices.length > 1) {
        state.returnData.devices.splice(index, 1)
      }
    }),

  addNewDevice: () =>
    set(state => {
      state.returnData.devices = [...state.returnData.devices, defaultDevice]
    }),
})

export const useReturnForm = create(immer(store))
