import React, { useEffect, useRef, useState } from "react"
import { useReturnForm } from "./ReturnState"

const AddressInput = ({ address }) => {
  const { updateReturn } = useReturnForm()
  const autoCompleteRef = useRef(null)
  const [error, setError] = useState("")
  const scriptTagRef = useRef()

  const loadScript = () => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.id = "google-api-address"

    if (script.readyState) {
      script.onreadystatechange = function() {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null
          handleScriptLoad()
        }
      }
    } else {
      script.onload = () => handleScriptLoad()
    }

    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_KEY}&libraries=places`
    document.getElementsByTagName("head")[0].appendChild(script)
    return script
  }

  const handleSelect = autocomplete => {
    const place = autocomplete?.getPlace()
    if (place?.name || !place.formatted_address) {
      return setError("Sorry, that address is not recognized.")
    } else {
      const address = place.formatted_address
      updateReturn({ name: "address", value: address })
    }
  }

  const handleScriptLoad = () => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["address"], componentRestrictions: { country: "us" } }
    )
    autocomplete.setFields(["formatted_address"])

    autocomplete.addListener("place_changed", () => handleSelect(autocomplete))
  }

  useEffect(() => {
    if (!scriptTagRef.current) {
      scriptTagRef.current = loadScript()
    }

    return () => {
      if (scriptTagRef.current) {
        const scripts = document
          .getElementsByTagName("head")[0]
          .getElementsByTagName("script")
        for (let i = 0; i < scripts.length; i++) {
          if (scripts.item(i).src.includes("maps.googleapis.com/maps")) {
            document
              .getElementsByTagName("head")[0]
              .removeChild(scripts.item(i))
          }
        }
      }
    }
  }, [])

  const onChange = e => {
    updateReturn({ name: "address", value: e.target.value })
    if (error) {
      setError("")
    }
  }

  return (
    <>
      <label htmlFor="address">
        <span>Return Address for Shipping Label*</span>
        <input
          ref={autoCompleteRef}
          required
          value={address}
          onChange={onChange}
          name="address"
          placeholder="Enter a location"
        />
      </label>
      {error && (
        <p className="error-text" style={{ display: "inline-block" }}>
          {error}
        </p>
      )}
    </>
  )
}

export default AddressInput
