import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import short from "short-uuid"
import { Wrapper, Container } from "../styles"
import SEO from "../components/seo"
import ReturnForm from "../components/returns/ReturnForm"
import { useScrollToTop } from "../hooks"

const ReturnsPage = () => {
  const translator = short()
  const [isConfirmShowing, setIsConfirmShowing] = useState(false)
  const [submissionId] = useState(translator.new())

  useScrollToTop(isConfirmShowing)

  return (
    <>
      <SEO
        title="Rachio Pro Distribution returns form"
        description="RGA submission form for Rachio Pros"
        pathname="/returns/"
        robots="noindex,nofollow"
      />
      <Wrapper width="tight">
        <div className="center-text">
          <h1 className="blue-text">Pro Distribution RGA Submission Form</h1>
          <p>This returns form is for Rachio Pros only.</p>
          <p>If you are not a pro and need to return your product, please contact <a href="mailto:support@rachio.com">support@rachio.com</a></p>
        </div>
        <hr />
      </Wrapper>
      <Container className="margins">
        <AnimatePresence>
          {isConfirmShowing ? (
            <motion.aside
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Wrapper width="tight" className="center-text">
                <p className="largest">
                  Your return request has been successfully submitted. You will
                  be receiving an email within the next 3-6 business days with
                  further instructions to complete the RGA process.
                </p>
                <h3>{`Confirmation: ${submissionId}`}</h3>
              </Wrapper>
            </motion.aside>
          ) : (
            <Wrapper width="tight" className="gray-bg">
              <ReturnForm
                submissionId={submissionId}
                setIsConfirmShowing={setIsConfirmShowing}
              />
            </Wrapper>
          )}
        </AnimatePresence>
      </Container>
    </>
  )
}

export default ReturnsPage
