import React, { useState } from "react"
import styled from "styled-components"
import Modal from "../Modal"
import { Button, TextButton } from "../Button"
import { Input, Textarea, Select } from "../Inputs"
import { Card, Flex } from "../../styles"
import { useReturnForm } from "./ReturnState"

const skus = [
  "8ZULWC-PRO - Rachio PRO White",
  "16ZULWC-PRO - Rachio PRO White",
  "8ZULWC-PS - Rachio PRO Exclusive (8 zone)",
  "16ZULWC-PS - Rachio PRO Exclusive (16 zone)",
  "16RACHBX - Outdoor enclosure",
  "6ZULWC-PRO-BLI - Rachio PRO Black, Indoor",
  "8ZULWC-PRO-BLI - Rachio PRO Black, Indoor",
  "16ZULWC-PRO-BLI - Rachio PRO Black, Indoor",
]

export type Device = {
  sku: string
  serial: string
  reason: string
  credit_or_replace: string
}

type Props = {
  index: number
  deviceCount: number
  device: Device
}

const DeviceForm = ({ index, device, deviceCount }: Props) => {
  const [isModalActive, setIsModalActive] = useState(false)
  const { updateDevice, removeDevice, updateDeviceRadio } = useReturnForm()

  const handleRemoveDevice = () => {
    removeDevice(index);
    setIsModalActive(false);
  }

  return (
    <>
      <DeviceFormWrapper>
        <Select
          name="sku"
          label="SKU"
          required
          hasDefaultOption
          defaultOption="Select a SKU"
          onChange={e =>
            updateDevice({
              index,
              name: e.target.name,
              value: e.target.value,
            })
          }
          options={skus}
          value={device.sku}
        />

        <Input
          name="serial"
          label="Serial"
          value={device.serial}
          required
          onChange={e =>
            updateDevice({
              index,
              name: e.target.name,
              value: e.target.value,
            })
          }
        />
        <Textarea
          name="reason"
          label="Reason"
          value={device.reason}
          required
          onChange={e =>
            updateDevice({
              index,
              name: e.target.name,
              value: e.target.value,
            })
          }
        />
        <Flex css="align-items: center">
          {deviceCount > 1 && (
            <div>
              <TextButton type="button" onClick={() => setIsModalActive(true)}>
                Remove
              </TextButton>
            </div>
          )}
        </Flex>
      </DeviceFormWrapper>
      <Modal
        isActive={isModalActive}
        closeAction={() => setIsModalActive(false)}
      >
        <Card css="background: var(--white); text-align: center">
          <p className="large">Remove device from submission form?</p>
          <Button
            css={`
              &:hover {
                color: var(--lightBlue);
              }
            `}
            onClick={() => setIsModalActive(false)}
            color="white"
          >
            Cancel
          </Button>
          <Button onClick={handleRemoveDevice}>Remove</Button>
        </Card>
      </Modal>
    </>
  )
}

export default DeviceForm

const DeviceFormWrapper = styled(Card)`
  background: var(--lightestGray);
  margin: 2rem 0;
  .input-select {
    margin-top: 0.5rem;
  }
`
