import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button } from "../../components/Button"
import { Input } from "../../components/Inputs"
import { below } from "../../styles"
import DeviceForm from "./DeviceForm"
import { useReturnForm } from "./ReturnState"
import AddressInput from "./AddressInput"
import { useLoad } from "../../global"
import { stripNonAlphanumeric, isValidEmail } from "../../utils/helpers"
import { triggerEmailWorkflow } from "../../lib"

type Device = {
  sku: string
  serial: string
  credit_or_replace: string
  reason: string
}

type ReturnData = {
  requestedBy: string
  companyName: string
  branch: string
  email: string
  phone: string
  address: string
  devices: Device[]
}

type Props = {
  submissionId: string
  setIsConfirmShowing: (_: boolean) => void
}

const ReturnForm = ({ submissionId, setIsConfirmShowing }: Props) => {
  const { isLoading, setIsLoading } = useLoad()
  const { returnData, initialize, addNewDevice, updateReturn } = useReturnForm()
  const [error, setError] = useState("")

  const defaultDevice: Device = {
    sku: "",
    serial: "",
    credit_or_replace: "credit",
    reason: "",
  }

  const defaultReturn: ReturnData = {
    requestedBy: "",
    companyName: "",
    branch: "",
    email: "",
    phone: "",
    address: "",
    devices: [defaultDevice],
  }

  useEffect(() => {
    initialize(defaultReturn)
  }, [])

  //  ? Creates array of submissions for rma google sheet
  //  * @ https://docs.google.com/spreadsheets/d/1WSF2pOUN4WMAEk4_QR4r1IOXSX8ZP3PVVN7GHQVcuNY/
  const createSubmissions = (id: string) => {
    const submissionData = returnData.devices.map(
      (device: Device, index: number) => {
        if (device.sku !== "" && device.serial !== "") {
          return [
            `${returnData.requestedBy} - ${returnData.companyName}${
              returnData.branch ? ` (${returnData.branch})` : ""
            }`,
            `${id}-${index + 1}`,
            device.sku.split(" ")[0],
            device.serial,
            device.reason || "",
            returnData.email,
            stripNonAlphanumeric(returnData.phone),
            device.credit_or_replace || "",
            returnData.address,
            new Date()
              .toJSON()
              .slice(0, 10)
              .replace(/-/g, "/"),
          ]
        }
        return []
      }
    )
    return submissionData
  }

  const onSubmit = async e => {
    e.preventDefault()

    if (!isValidEmail(returnData.email)) {
      return setError("Please enter valid email address.")
    }

    setIsLoading(true)
    const submissions = createSubmissions(submissionId)

    try {
      const response = await fetch(
        "https://7i3j2g0d61.execute-api.us-east-1.amazonaws.com/v1/rma",
        {
          method: "put",
          body: JSON.stringify(submissions),
        }
      )
      console.log("response", response)
      if (response.ok) {
        await triggerEmailWorkflow(
          {
            ...returnData,
            phone: stripNonAlphanumeric(returnData.phone),
          },
          submissionId
        )
        setIsLoading(false)
        setIsConfirmShowing(true)
        setError("")
      }
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      setError("There was an error processing your request")
    }
  }

  return (
    <div>
      <Form onSubmit={onSubmit} className="container">
        <Input
          name="requestedBy"
          label="Requested By"
          value={returnData.requestedBy}
          required
          onChange={e =>
            updateReturn({ name: e.target.name, value: e.target.value })
          }
        />
        <Input
          name="companyName"
          label="Company Name"
          value={returnData.companyName}
          required
          onChange={e =>
            updateReturn({ name: e.target.name, value: e.target.value })
          }
        />
        <Input
          name="branch"
          label="Branch"
          value={returnData.branch}
          onChange={e =>
            updateReturn({ name: e.target.name, value: e.target.value })
          }
        />
        <Input
          name="email"
          label="Email Address"
          type="email"
          value={returnData.email}
          required
          onChange={e =>
            updateReturn({ name: e.target.name, value: e.target.value })
          }
        />
        <Input
          name="phone"
          label="Phone"
          type="tel"
          pattern="^\(?\d{3}\D*\d{3}\D*\d{4}$"
          value={returnData.phone}
          required
          onChange={e =>
            updateReturn({ name: e.target.name, value: e.target.value })
          }
        />
        <AddressInput address={returnData.address} />
        {returnData.devices.map((device: Device, index: number) => (
          <DeviceForm
            index={index}
            device={device}
            deviceCount={returnData.devices.length}
            key={`DEVICE_SUBMISSION_${index}`}
          />
        ))}
        <div className="center-text">
          <Button
            type="button"
            onClick={addNewDevice}
            color="white"
            css={`
              &:hover {
                color: var(--primaryColor);
                background: var(--white);
              }
            `}
          >
            Add Another
          </Button>
        </div>
        <SubmitButton type="submit" disabled={isLoading} className="margins">
          {isLoading ? "Processing..." : "Submit"}
        </SubmitButton>
        {error && <p className="error-text">{error}</p>}
      </Form>
    </div>
  )
}

export default ReturnForm

const SubmitButton = styled(Button)`
  width: 100%;
`

const Form = styled.form`
  .input-wrapper {
    display: flex;
    flex-wrap: wrap;
    .input-label + .input-label {
      margin-left: 2rem;
    }
  }

  ${below.medium`
    padding-top: var(--basePadding);
    .input-wrapper {
      display: block;
      .input-label + .input-label {
        margin-left: 0;
      }
    }
  `};
`
