import React from "react"
import styled from "styled-components"

type InputProps = {
  name: string
  disabled?: boolean
  value: string | number | null
  label?: string
  type?: string
  placeholder?: string
  pattern?: string | undefined
  title?: string
  onChange: (args: any) => void
  onBlur?: () => void
  required?: boolean
}

export const Input = ({
  name,
  label = "",
  onChange,
  value = "",
  onBlur,
  type = "text",
  disabled = false,
  placeholder = "",
  pattern = undefined,
  title = "",
  required = false,
}: InputProps) => (
  <Label className="input-label" htmlFor={name}>
    {label && (
      <span className="input-title">
        {label}
        {required && "*"}
      </span>
    )}
    <input
      className="input-text"
      placeholder={placeholder}
      pattern={pattern}
      title={title}
      onChange={onChange}
      onBlur={onBlur}
      id={name}
      required={required}
      name={name}
      type={type}
      value={value}
      disabled={disabled}
    />
  </Label>
)

type TextareaProps = {
  name: string
  value: string
  label?: string
  onChange: (args: any) => void
  required?: boolean
  rows?: number
  placeholder?: string
  maxLength?: number
}

export const Textarea = ({
  name,
  label = "",
  onChange,
  value = "",
  required = false,
  placeholder = "",
  maxLength = 1020,
  rows = 6,
}: TextareaProps) => (
  <Label className="input-label" htmlFor={name}>
    {label && (
      <span className="input-title">
        {label}
        {required && "*"}
      </span>
    )}
    <textarea
      rows={rows}
      required={required}
      placeholder={placeholder}
      maxLength={maxLength}
      className="input-textarea"
      onChange={onChange}
      id={name}
      name={name}
      value={value}
    />
  </Label>
)

type SelectProps = {
  name: string
  label?: string
  onChange: (args: any) => void
  value: string
  required?: boolean
  options: string[]
  hasDefaultOption: boolean
  defaultOption?: string
}

export const Select = ({
  name,
  label = "",
  onChange,
  value = "",
  required = false,
  options,
  hasDefaultOption = false,
  defaultOption = "Select an option",
}: SelectProps) => (
  <Label className="input-label" htmlFor={name}>
    {label && (
      <span className="input-title">
        {label}
        {required && "*"}
      </span>
    )}
    <select
      className="input-select"
      id={name}
      name={name}
      value={value}
      required={required}
      onChange={onChange}
    >
      {hasDefaultOption && (
        <option value="" disabled hidden>
          {defaultOption}
        </option>
      )}

      {options.map(option => (
        <option value={option} key={option}>
          {option}
        </option>
      ))}
    </select>
  </Label>
)

const Label = styled.label`
  > input,
  select,
  textarea {
    margin-top: 0.5rem;
  }
`

export const Checkbox = styled.input.attrs({
  type: "checkbox",
})`
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 2px solid var(--lineColor);
  border-radius: 0.3rem;
  padding: 0.5rem;
  display: inline-block;
  position: relative;
  height: 2.4rem;
  width: 2.4rem;
  min-height: 2.4rem;
  min-width: 2.4rem;
  margin-top: 0.5rem;
  &:hover {
    cursor: pointer;
  }
  &:checked {
    background: var(--primaryColor);
    border: 2px solid var(--primaryColor);

    &:after {
      content: "\\2713";
      font-size: 1.6rem;
      position: absolute;
      top: 0;
      left: 3px;
      color: var(--white);
    }
  }
`
